import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const ThanksPage = () => {

    return (
        <Layout>
            <SEO title={`Thanks`} />

            <div className="o-container p-contact">
                <h1 className="u-h1 u-c o-heading">Netflix<strong>& chill</strong></h1>

                <p className={"u-l"}>Thanks for getting in touch with us.<br /> Your request will be picked up briefly.</p>
            </div>
        </Layout>
    );
};

export default ThanksPage;
